import api from './api'

export default {
    list (page) {
        return api.get('/sales/' + page)
    },
    total () {
        return api.get('/sales/total')
    },
    monthStatistics () {
        return api.get('/sales/statistics/month')
    },
    weekStatistics () {
        return api.get('/sales/statistics/week')
    },
    yearStatistics () {
        return api.get('/sales/statistics/year')
    },
    allByCustomer (foreignId) {
        return api.get('/sales-by-customer/' + foreignId)
    },
    shops () {
        return api.get('/shops')
    },
    shopsSearch (data) {
        return api.post('/shop/search', data)
    },
    search (search) {
        return api.post('/sales/search', search)
    },
    searchTotal (search) {
        return api.post('/sales/search-total', search)
    },
    searchStats (search) {
        return api.post('/sales/search-stats', search)
    }
}
