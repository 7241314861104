import api from './api'

export default {
    list (page) {
        return api.get('/products/' + page)
    },
    total () {
        return api.get('/products/total')
    },
    search (search) {
        return api.post('/catalog/search', search)
    },
    searchTotal (search) {
        return api.post('/catalog/search-total', search)
    },
    searchStats (search) {
        return api.post('/catalog/search-stats', search)
    }
}
