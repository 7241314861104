import api from './api'

export default {
    one (id) {
        return api.get('/customer/' + id)
    },
    byForeignId (id) {
        return api.get('/customer-by-foreign-id/' + id)
    },
    list (page) {
        return api.get('/customers/' + page)
    },
    total () {
        return api.get('/customers/total')
    },
    search (search) {
        return api.post('/customers/search', search)
    },
    searchTotal (search) {
        return api.post('/customers/search-total', search)
    },
    searchStats (search) {
        return api.post('/customers/search-stats', search)
    }
}
