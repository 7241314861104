<script>
import {Line} from 'vue-chartjs'
import sale from '@/api/sale';
import {hexToRgba} from '@coreui/utils/src'

export default {
  name: 'SalesChart',
  extends: Line,
  props: {
    period: {
      type: String,
      required: true
    }
  },
  created() {
    if (this.period === 'week') {
      sale.weekStatistics()
          .then(r => this.renderData(r.data))
    } else if (this.period === 'month') {
      sale.monthStatistics()
          .then(r => this.renderData(r.data))
    } else if (this.period === 'year') {
      sale.yearStatistics()
          .then(r => this.renderData(r.data))
    }
  },
  methods: {
    renderData (data) {
      data = data.sort((a,b) => {
        if (a.date > b.date) {
          return 1;
        }
        if (a.date < b.date) {
          return -1;
        }
        return 0;
      })

      this.renderChart({
        labels: data.map(i => i.date),
        datasets: [
          {
            label: 'Количество продаж',
            borderColor: '#3399ff',
            backgroundColor: 'transparent',
            data: data.map(i => i.count),
            yAxisID: 'A',
            borderWidth: 2
          },
          {
            label: 'Сумма продаж',
            borderColor: '#2eb85c',
            backgroundColor: hexToRgba('#2eb85c', 20),
            data: data.map(i => i.sum),
            yAxisID: 'B',
            borderWidth: 2
          }
        ]
      }, {
        maintainAspectRatio: false,
        responsive: true,
        stacked: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              display: true,
              position: 'left',
            },
            {
              id: 'B',
              type: 'linear',
              display: true,
              position: 'right',
              gridLines: {
                drawOnChartArea: false
              }
            }
          ],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        },
        legend: {
          display: false
        }
      })
    }
  },
  computed: {}
}
</script>
