<template>
  <div>
    <CRow>
      <CCol col="12" sm="6" lg="4">
        <CWidgetIcon
            :header="String(totals.customers)"
            text="Покупатели"
            color="gradient-info"
            :icon-padding="false"
        >
          <CIcon name="cil-group" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol col="12" sm="6" lg="4">
        <CWidgetIcon
            :header="String(totals.products)"
            text="Товары"
            color="gradient-danger"
            :icon-padding="false"
        >
          <CIcon name="cil-gift" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </CCol>
      <CCol col="12" sm="6" lg="4">
        <CWidgetIcon
            :header="String(totals.sales)"
            text="Продажи"
            color="gradient-success"
            :icon-padding="false"
        >
          <CIcon name="cil-cart" class="mx-5 " width="24"/>
        </CWidgetIcon>
      </CCol>
    </CRow>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Продажи</h4>
            <div class="small text-muted">(кол-во и сумма)</div>
          </CCol>
          <CCol sm="7" class="d-none d-md-block">
            <CButton color="primary" class="float-right">
              <CIcon name="cil-cloud-download"/>
            </CButton>
            <CButtonGroup class="float-right mr-3">
              <CButton
                  color="outline-secondary"
                  v-for="(value, key) in ['week', 'month', 'year']"
                  :key="key"
                  class="mx-0"
                  :pressed="value === selected"
                  @click="slectRange(value)"
              >
                {{ periods[value] }}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
        <SalesChart :period="selected" :key="chartKey" style="height:300px;margin-top:40px;"/>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import SalesChart from '@/views/dashboard/SalesChart';
import product from '@/api/product';
import sale from '@/api/sale';
import customer from '@/api/customer';

export default {
  name: 'Dashboard',
  components: {
    SalesChart
  },
  data() {
    return {
      chartKey: 0,
      selected: 'month',
      periods: {
        'month': 'Месяц',
        'week': 'Неделя',
        'year': 'Год'
      },
      totals: {
        sales: 0,
        products: 0,
        customers: 0
      }
    }
  },
  methods: {
    slectRange (value) {
      this.selected = value;
      this.chartKey ++;
    }
  },
  created() {
    this.$store.commit('common/set', ['breadcrumbs', [
      {
        text: 'Главная'
      }
    ]])
    product.total()
           .then(r => this.totals.products = r.data.total)
    sale.total()
        .then(r => this.totals.sales = r.data.total)
    customer.total()
            .then(r => this.totals.customers = r.data.total)
  }
}
</script>
